import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {useTranslation} from "react-i18next";
import './Dashboard.css';

const Dashboard1: React.FC = () => {
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t("default:bilbao.dashboard1")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <iframe className={'webPage'} src={"https://cloud.zerogravity.fi/shiny/bilbaoportco/"}/>
            </IonContent>
        </IonPage>
    );
};

export default Dashboard1;
